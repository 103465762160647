class LeftColumnMenu {
    constructor() {
        this.active = null;
    }
    toggle(trigger) {
        let previousBtn = null;
        let nextBtn = null;

        if (!this.active) {
            nextBtn = trigger;
            this.active = trigger;
        }
        else if (this.active.is(trigger)) {
            previousBtn = trigger;
            this.active = null;
        }
        else {
            previousBtn = this.active;
            nextBtn = trigger;
            this.active = trigger;
        }

        if (previousBtn) {
            previousBtn.removeClass('active');
            previousBtn.siblings().show();
            $(previousBtn.attr('data-target') + '.ib-mobile-listing').removeClass('active');
        }
        if (nextBtn) {
            nextBtn.addClass('active');
            nextBtn.siblings('button').hide();
            $(nextBtn.attr('data-target') + '.ib-mobile-listing').addClass('active');
        }
    }
}

export default class MobileLeftColumnMenu {
    init() {
        const selector = '.ib-left-column-actions .menu-toggle';
        const buttons = $(selector);
        if (buttons.length) {
            const mobileMenu = new LeftColumnMenu();
            $('#left-column').on('click', selector, function() {
                mobileMenu.toggle($(this));
            });
            $('#left-column').on('click', '.ib-mobile-listing .menu-toggle-close', function() {
                const originalBtn = $('.menu-toggle[data-target="' + $(this).attr('data-target') + '"]');
                mobileMenu.toggle(originalBtn);
            });
            window.mobileMenu = mobileMenu;
        }
    }
}