
export default class FacetedSearchExtraFeatures {
    init() {
        $('#search_filters .faceted-slider').each(function() {
            const slider_id = $(this).data('sliderId');
            const inputs = $(`#facet_inputs_${slider_id} > input`);
            const slider_node = $(`#slider-range_${slider_id}`);
            const range = {
                min: parseFloat($(this).data('sliderMin')),
                max: parseFloat($(this).data('sliderMax')),
            };
            range.step = (range.max - range.min < 1) ? 0.1 : 1;

            // $(this).on("slidechange", function(event, ui) {
            $(this).on("slide", function(event, ui) {
                inputs.eq(0).val(ui.values[0]);
                inputs.eq(1).val(ui.values[1]);
                // console.log(inputs);
                // console.log(ui.values[0]);
                // console.log(ui.values[1]);
            });

            inputs.on("change", function() {
                // slider_node.slider("values", 0, inputs.eq(0).val());
                // slider_node.slider("values", 1, inputs.eq(1).val());
                let inputMin = parseFloat(inputs.eq(0).val());
                let inputMax = parseFloat(inputs.eq(1).val());
                const localMin = (isNaN(inputMin)) ? range.min : Math.max(inputMin, range.min);
                const localMax = (isNaN(inputMax)) ? range.max : Math.min(inputMax, range.max);
                let calcMin, calcMax;

                if ($(this).is(inputs.eq(0))) {
                    calcMin = Math.min(localMin, localMax - range.step);
                    calcMax = localMax;
                }
                else {
                    calcMin = localMin;
                    calcMax = Math.max(localMax, calcMin + range.step);
                }
                
                if (range.step == 0.1) {
                    calcMin = calcMin.toFixed(1);
                    calcMax = calcMax.toFixed(1);
                }
                
                slider_node.data('uiSlider').options.stop(null, {
                    values: [
                        parseFloat(calcMin),
                        parseFloat(calcMax)
                    ]
                });
            });
        });
    }
}