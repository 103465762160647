class ObserverFacade {
    constructor(settings) {
        this.observer = null;
        this.cb = () => {};
    }
    Observe(triggerNode) {
        triggerNode = this.GetNode(triggerNode);
        if (triggerNode == null) {
            return;
        }
        else {
            const _observer = this.observer;
            triggerNode.each(function() {
                _observer.observe($(this).get(0));
            });
        }
        return this;
    }
    Unobserve(node) {
        node = this.GetNode(node);
        if (node !== null) {
            const _observer = this.observer;
            node.each(function() {
                _observer.unobserve($(this).get(0));
            });
        }
        return this;
    }
    Disconnect() {
        this.observer.disconnect();
        return this;
    }
    GetNode(node) {
        if (!(node instanceof jQuery)) {
            node = $(node);
            console.warn("Passed node had to be converted to jQuery!");
        }
        if (!node.length) {
            console.error("Invalid node");
            return null;
        }
        return node;
    }
    Callback(entries) {
        entries.forEach((entry) => {
            this.cb(entry);
        });
    }
}

export class DockableObserver extends ObserverFacade {
    constructor(settings) {
        super(settings);
        // register observer
        if (this.observer == null) {
            const defaultOptions = { root: null, rootMargin: '0px', threshold: 0 };
            this.observer = new IntersectionObserver((entries, observer) => this.Callback(entries), settings.observerOptions ? settings.observerOptions : defaultOptions);
        }
        if (settings.observe) {
            this.Observe(settings.observe);
        }
        if (settings.callback) {
            this.cb = settings.callback;
        }
        return this;
    }
}

export class ResizableObserver extends ObserverFacade {
    constructor(settings) {
        super(settings);
        // register observer
        if (this.observer == null) {
            this.observer = new ResizeObserver((entries) => this.Callback(entries));
        }
        if (settings.observe) {
            this.Observe(settings.observe);
        }
        if (settings.callback) {
            this.cb = settings.callback;
        }
        return this;
    }
}

export default class MobileMenu {
    init() {
        // FAKE MENU BUTTON
        $('.ib-trigger-menu').on('click', () => { 
            $('.ybc-menu-toggle.ybc-menu-btn').trigger('click'); 
        });

        // MOBILE MENU RETURN BUTTON
        $('.ib-return').on('click', function() {
            $(this).closest('.ib-subcategories').siblings('.opened').removeClass('opened').addClass('closed');
        });

        // DOCK MENU
        new DockableObserver({
            observe: $('#mobileMenuObserverBottom'),
            callback: function(entry) {
                if (entry.isIntersecting >= 1) {
                    $('.header-top').removeClass("docked");
                } else {
                    $('.header-top').addClass("docked");
                }
            }
        });
        new DockableObserver({
            observe: $('#mobileMenuObserverTop'),
            callback: function(entry) {
                if (entry.isIntersecting >= 1) {
                    $('.header-top').removeClass("dock-ready").css('minHeight', 'unset');
                } else {
                    $('.header-top').css('minHeight', $('.header-top').outerHeight());
                    $('.header-top').addClass("dock-ready");
                }
            }
        });
        // new ResizableObserver({
        //     observe: $('.header-top-right'),
        //     callback: function(entry) {
        //         let height;
        //         const maxHeight = 93;
        //         if (entry.contentBoxSize) {
        //             // Firefox implements `contentBoxSize` as a single content rect, rather than an array
        //             const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;
        //             height = contentBoxSize.blockSize;
        //         } else {
        //             height = entry.contentRect.height;
        //         }
        //         $('.header-top').css('minHeight', Math.min(height + 40, maxHeight));    // top padding is 16, bottom padding is 24
        //     }
        // });
    }
}
