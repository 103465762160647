import $ from 'jquery';

export default class BlockWishlist {
    init() {
        const el = $('#_desktop_wishlist > a');
        if (el.length) {
            window.WishlistEventBus.$on('showToast', (params) => { 
                $.ajax({
                    method: "POST",
                    url: el.attr('data-refresh'),
                    data: {},
                    success: function (data) {
                        const response = JSON.parse(data);
                        let nbProducts = 0;
                        response.wishlists.forEach(wishlist => {
                            if (wishlist.default) {
                                nbProducts = wishlist.nbProducts;
                            }
                        });
                        $('.wishlist-count', el).html(nbProducts);
                    }
                })
            });
        }
    }
}