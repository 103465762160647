export default class IbInputCheckboxHandler {
  constructor(node, callback) {
      this.node = node;
      this.inputs = $('input[type="checkbox"]', node);
      this.events = {
          Start: () => { 
              if (this.CurrentValue() == undefined ) {
                  this.inputs.first().prop('checked', true);
                  this.Emit("Change");
              }
          },
          Change: callback,
      };
      this.inputs.on('change', () => { this.Emit("Change"); });
      this.Emit("Start");
  }
  Emit(event) {
      // pass current value and node to callbacks
      this.events[event](
          {
              value: this.CurrentValue(), 
              index: this.CurrentIndex()
          }, 
          this.node
      );
  }
  CurrentValue() {
      return this.inputs.filter(":checked").val();
  }
  CurrentIndex() {
      for (let index = 0; index < this.inputs.length; index++) {
          if ($(this.inputs.get(index)).is(':checked')) {
              return index;
          }
      }
      return -1;
  }
}